import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import flairLogo from '../images/flair_airlines_logo_white.svg'; // Import the logo image
import userIcon from '../images/user.svg'; // Import the user icon image
import './header.css';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    
    const { t } = useTranslation();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="header">
            <div className="logo">
            <a href="https://flyflair.com/" title="View Deals"><img src={flairLogo} alt="Flair Airlines Logo" className="logo-image" /></a>
            </div>
            <nav className={`navigation ${menuOpen ? 'open' : ''}`} aria-label="Main Navigation">
                <ul>
                    <li><a href="https://flyflair.com/" title="View Deals">{t('deals')}</a></li>
                    <li><a href="https://flights.flyflair.com/en-ca/destinations" title="Explore Destinations">{t('destinations')}</a></li>
                    <li><a href="https://flights.flyflair.com/en-ca/where-we-fly" title="See Route Map">{t('route map')}</a></li>
                    <li><a href="https://flyflair.com/travel-info" title="Get Travel Information">{t('travel info')}</a></li>
                    <li><a href="https://flyflair.com/travel-info/optional-fees-and-charges" title="Learn About Optional Fees">{t('optional fees')}</a></li>
                    <li><a href="https://flyflair.com/support" title="Get Support">{t('support')}</a></li>
                </ul>
            </nav>
            <div className="user-options">
                <LanguageSwitcher />
                <a href="https://flyflair.com/sign-in"><img src={userIcon} alt="User Icon" className="user-icon" /></a>
            </div>
            <button className="menu-toggle" onClick={toggleMenu}>
                ☰
            </button>
        </header>
    );
};

export default Header;
