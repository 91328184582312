import React from 'react';
import './BottomNav.css';
import { useTranslation } from 'react-i18next';

const BottomNav = () => {
  const { t } = useTranslation();
  return (
    <div className="bottom-nav">
      <a href="https://oci.flyflair.com/" className="nav-item">{t('check in')}</a>
      <a href="https://flyflair.com/my-trip" className="nav-item">{t('my bookings')}</a>
    </div>
  );
};

export default BottomNav;