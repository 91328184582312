import React, { useState } from 'react';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import ResultsTable from "./ResultsTable";
import DatePicker from 'react-datepicker';
import loader from '../images/loading.gif';
import "react-datepicker/dist/react-datepicker.css";
import './Search.css';
import '../fonts.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faPlane } from '@fortawesome/free-solid-svg-icons';

const Search = () => {
  const [flightNumber, setFlightNumber] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [flightData, setFlightData] = useState([]);
  const { t } = useTranslation();
  // const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // State for loading status

  const handleSearch = async () => {
    setLoading(true); // Set loading to true when starting the search
    try {
      const formattedDate = departureDate.toLocaleDateString('en-CA'); // en-CA locale formats date as YYYY-MM-DD
      const response = await axios.post(
        "https://flightstatus.flyflair.com/flightstatus",
        // "http://localhost:5000/flightstatus",
        {
          "flightNumber": flightNumber,
          "departureDate": formattedDate,
          timeout: 80000,
        }
      );
      setFlightData(response.data);
      // setError("");
    } catch (err) {
      setFlightData(null);
      // setError("Flight not found.");
      // console.error(err);
      console.log(flightData)
    } finally {
      setLoading(false); // Set loading to false after the request is complete
    }
  };


  // Calculate the min and max dates
  const today = new Date();
  const minDate = new Date(today);
  minDate.setDate(today.getDate() - 2);
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + 2);

  return (
    <div className="search-container">
      <h1 className="search-heading">{t('flight status')}</h1>
      <div className="search-form">
        <div className="input-group">
          <div className="input-group-addon"><FontAwesomeIcon icon={faPlane} className="input-group-icon" /></div>
          <span className="flight-prefix">F8 -</span>
          <input
            type="text"
            className="search-input"
            value={flightNumber}
            onChange={(e) => setFlightNumber(e.target.value)}
            placeholder={`${t('flight number')}`}
          />
        </div>
        <div className="input-group">
          <div className="input-group-addon"><FontAwesomeIcon icon={faCalendarAlt} className="input-group-icon" /></div>
          <DatePicker
            selected={departureDate}
            onChange={(date) => setDepartureDate(date)}
            minDate={minDate}
            maxDate={maxDate}
            dateFormat="MM/dd/yyyy"
            className="search-input"
            placeholderText={`${t('departure date')}`}
          />
        </div>
      </div>
      <button className="search-button" onClick={handleSearch}>
        {t('search')}
      </button>
      <div>
        {/* Show loading screen if loading is true */}

        {loading && <div className="loading-screen"><img src={loader} alt="loading" className="page-loader" /></div>}
        {/* Show results if flightData is available */}
        {/* {flightData && ( */}
        <div className="results-container">
          <ResultsTable flights={flightData} />
        </div>
        {/* )} */}
        {/* Show error message if error occurs */}
        {/* {error && <p className="error-message">{error}</p>} */}
      </div>

    </div>
  );
};

export default Search;
