import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGlobe, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const [langText, setLangText] = useState('EN');

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        if (lng === 'en') {
            setLangText('EN')
        } else if (lng === 'fr') {
            setLangText('FR')
        }
    };
    return (
        <div className="language-switcher">
            <div class="dropdown">
                <button class="dropbtn"><FaGlobe className="icon" /><span class="langtext">{langText}</span></button>
                <div class="dropdown-content">
                    <span onClick={() => changeLanguage('en')}>english</span >
                    <span onClick={() => changeLanguage('fr')}>français</span >
                </div>
            </div>
        </div>
    );
};
export default LanguageSwitcher;