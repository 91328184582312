// App.js
import React from "react";
import './App.css';
import './fonts.css';
import Search from './components/Search';
import Header from './components/header';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import i18n from './i18n';
import BottomNav from "./components/bottomNav"; // Import BottomNav component

function App() {
  return (
    <div className="App">
      <Header />
      <Search />
      <BottomNav /> {/* Add BottomNav component */}
    </div>
  );
}

export default App;
