import React from "react";
import "./ResultsTable.css";
import airplane from '../images/flight.svg'; // Import the logo image
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function ResultsTable({ flights }) {
  const { t } = useTranslation();
  if (flights == null) {
    return (
      <div className="no-flights-message">
        <div><FontAwesomeIcon icon={faSearch} className="search-icon" /> {t('Flight not found')} </div>
        <ul>
          <span>{t('Please ensure the flight and departure date are correct')} </span>
        </ul>
      </div>
    )
  }

  return (
    <div className="results-table-wrapper">
      {flights.map((flight, index) => (
        <div key={index} className="flight-card">
          <div className="flight-card-header">
            <div className="flight-number">{flight.flight_number}</div>
            <div className={`flight-status-${getStatusClass(flight.flight_status)}`}>
            {t(`${changeStatusWording(flight.flight_status)}`).toLowerCase()} 
            </div>
          </div>

          <div className="flight-card-body">
            <div className={`time-${getStatusClass(flight.flight_status)}`}> {flight.actual_time_of_aircraft_departure_local || flight.estimated_time_of_aircraft_departure_local}
              
            </div>
            <div className="icon"><img src={airplane} alt="airplane" className="airplane" /></div>
            <div className={`time-${getStatusClass(flight.flight_status)}`}> {flight.actual_time_of_aircraft_arrival_local || flight.estimated_time_of_aircraft_arrival_local}
              
            </div>
          </div>
          <div className="flight-card-body">
            <div className="date-variation-departure">
              {(getDateVariation(flight.scheduled_date_of_aircraft_departure_local, flight.estimated_date_of_aircraft_departure_local) !== null
                && getDateVariation(flight.scheduled_date_of_aircraft_departure_local, flight.estimated_date_of_aircraft_departure_local) !== 0
                && getDateVariation(flight.scheduled_date_of_aircraft_departure_local, flight.estimated_date_of_aircraft_departure_local) !== '0')
                ? `+${getDateVariation(flight.scheduled_date_of_aircraft_departure_local, flight.estimated_date_of_aircraft_departure_local)} ${t('day')}` : null}
            </div>
            <div className="date-variation-arrival">
              {(getDateVariation(flight.scheduled_date_of_aircraft_arrival_local, flight.estimated_date_of_aircraft_arrival_local) !== null
                && getDateVariation(flight.scheduled_date_of_aircraft_arrival_local, flight.estimated_date_of_aircraft_arrival_local) !== 0
                && getDateVariation(flight.scheduled_date_of_aircraft_arrival_local, flight.estimated_date_of_aircraft_arrival_local) !== '0')
                ? `+${getDateVariation(flight.scheduled_date_of_aircraft_arrival_local, flight.estimated_date_of_aircraft_arrival_local)} ${t('day')}` : null}
            </div>
          </div>
          <div className="flight-card-body">
            <div className="details">
              <span className={`scheduled-departure-${getState(flight.scheduled_time_of_aircraft_departure_local, flight.estimated_time_of_aircraft_departure_local, flight.actual_time_of_aircraft_departure_local, flight.flight_status)}`}>
              {t('Sched.').toLowerCase()} {flight.scheduled_time_of_aircraft_departure_local}</span>
              <span className="city"><b>{capitalizeWords(flight.departure_city)}</b></span>
            </div>
            <div className="details">
              <span className={`scheduled-arrival-${getState(flight.scheduled_time_of_aircraft_arrival_local, flight.estimated_time_of_aircraft_arrival_local, flight.actual_time_of_aircraft_arrival_local, flight.flight_status)}`}>
              {t('Sched.').toLowerCase()} {flight.scheduled_time_of_aircraft_arrival_local}</span>
              <span className="city"><b>{capitalizeWords(flight.arrival_city)}</b></span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function getStatusClass(status) {
  if (status === "Scheduled") {
    return "scheduled";
  } else if (status === "Arrived" || status === "Departed") {
    return "arrived-departed";
  } else if (status === "Canceled") {
    return "canceled";
  } else if (status === "Delayed") {
    return "delayed";
  } else {
    return "";
  }
}

function changeStatusWording(status) {
  if (status === "Scheduled") {
    return "On time";
  } else if (status === "Arrived") {
    return "Arrived";
  } else if (status === "Departed") {
    return "Departed";
  } else if (status === "Delayed") {
    return "Delayed";
  } else if (status === "Canceled") {
    return "Cancelled";
  } else {
    return status;
  }
}

function getState(schedule, estimate, actual, status) {
  if (schedule !== estimate || status === "Canceled") {
    return "changed";
  } else if (schedule !== actual && actual !== null) {
    return "changed";
  } else {
    return "unchanged";
  }
}

function getDateVariation(schedule, estimate) {
  if (schedule === 0 || schedule == null || estimate === 0 || estimate == null) {
    return 0;
  } else {
    const schedule_date = new Date(schedule);
    const estimate_date = new Date(estimate);
    const timeDifference = Math.abs(estimate_date - schedule_date); // Difference in milliseconds
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert to days
    // console.log(`Scheduled: ${schedule_date}`)
    // console.log(`Estimated: ${estimate_date}`)
    return dayDifference;
  }
}

const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
};

export default ResultsTable;
